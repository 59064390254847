const floorLevel = 50;

export class Player {
  image: Phaser.GameObjects.Sprite;
  zone: Phaser.GameObjects.Zone;
  text: Phaser.GameObjects.Text;
  nick: string;

  obstacleDetector: Phaser.GameObjects.Zone;

  private startX = 100;
  private startY = 500;

  private isCrouching = false;
  private _isDead = false;

  constructor(scene: Phaser.Scene, nick: string) {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    this.nick = nick;
    this.image = scene.add.sprite(this.startX, this.startY, "player");
    this.zone = scene.add.zone(this.startX, this.startY, this.image.width, this.image.height);
    scene.physics.add.existing(this.zone, false);

    this.obstacleDetector = scene.add.zone(this.startX, this.startY, this.image.width * 0.6, this.image.height * 0.8);
    scene.physics.add.existing(this.obstacleDetector, false);

    const detectorBody = this.obstacleDetector.body as Phaser.Physics.Arcade.Body;

    detectorBody.immovable = false;
    detectorBody.allowGravity = false;
    detectorBody.setGravity(0, 0);
    detectorBody.debugBodyColor = 0x00ffff;

    const nickMasked = nick.substring(0, 20);
    this.text = scene.add.text(this.startX, this.startY - 50, nickMasked, { color: "white" });
    this.text.tint = parseInt(randomColor, 16);
  }

  public update() {
    const body = this.getBody();

    const textOffset = (this.text.width - body.width) / 2;
    this.text.setPosition(body.x - textOffset, body.y - 20);

    const imageOffset = new Phaser.Math.Vector2(this.image.width / 2, this.image.height / 2);
    if (this.isCrouching) {
      imageOffset.y += this.image.height / 4;
    }

    this.image.scaleY = this.isCrouching ? 0.5 : 1;
    this.image.setPosition(body.x + imageOffset.x, body.y + imageOffset.y);

    this.obstacleDetector.scaleY = this.isCrouching ? 0.6 : 1;

    this.obstacleDetector.setPosition(
      body.x + imageOffset.x,
      body.bottom - (this.obstacleDetector.height * this.obstacleDetector.scaleY) / 2
    );
  }

  public jump() {
    if (this.getBody().touching.down === false && !this.isCrouching) {
      return;
    }

    this.getBody().setVelocityY(-400);
  }

  public crouch() {
    if (this.isCrouching) {
      return;
    }

    this.isCrouching = true;
    const event = this.image.scene.time.addEvent({
      delay: 1500,
      callback: () => {
        this.isCrouching = false;
      },
    });
  }

  public moveRight() {
    if (this.zone.x > this.image.scene.game.canvas.width - 100) {
      return;
    }

    const tween = this.image.scene.tweens.add({
      targets: this.zone,
      x: this.zone.x + 100,
      ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: 500,
      repeat: 0, // -1: infinity
      yoyo: false,
    });
  }

  public moveLeft() {
    if (this.zone.x < 150) {
      return;
    }

    const tween = this.image.scene.tweens.add({
      targets: this.zone,
      x: this.zone.x - 100,
      ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: 500,
      repeat: 0, // -1: infinity
      yoyo: false,
    });
  }

  public getBody(): Phaser.Physics.Arcade.Body {
    return this.zone.body as Phaser.Physics.Arcade.Body;
  }

  public isDead(): boolean {
    return this._isDead;
  }

  public die(removeCallback: () => void) {
    this._isDead = true;
    const duration = 500;

    // Random X between -200 and 200
    const randomX = Math.random() * 400 - 200;

    this.getBody().setVelocityY(-500);
    this.getBody().setVelocityX(randomX);
    this.getBody().setImmovable(true);
    this.getBody().allowGravity = false;

    this.obstacleDetector.destroy();

    // Rotation tween
    this.image.scene.tweens.add({
      targets: this.image,
      angle: 360,
      ease: "Linear",
      scale: 0.3,
      duration: duration,
      repeat: 0,
      yoyo: false,
    });

    // Add time event to destroy the player
    const event = this.image.scene.time.addEvent({
      delay: duration,
      callback: () => {
        this.image.destroy();
        this.text.destroy();
        this.zone.destroy();
        removeCallback();
      },
    });
  }
}
