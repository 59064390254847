import { GRID_HEIGHT, GRID_WIDTH, PLAYER_TILE_DURATION, TILE_SIZE } from "./constants";
import { PlayerMovedEvent } from "./events/player-events";

export class Player {
  nick: string;
  image: Phaser.GameObjects.Sprite;
  zone: Phaser.GameObjects.Zone;
  text: Phaser.GameObjects.Text;
  textBackground: Phaser.GameObjects.Rectangle;

  gridPosition: Phaser.Math.Vector2 = new Phaser.Math.Vector2(0, 0);
  isMoving: boolean = false;
  scene: Phaser.Scene;

  constructor(scene: Phaser.Scene, nickname: string) {
    this.scene = scene;
    // Random position
    this.gridPosition = new Phaser.Math.Vector2(
      Phaser.Math.Between(0, GRID_WIDTH - 1),
      Phaser.Math.Between(0, GRID_HEIGHT - 1)
    );

    const position = this.getRenderPosition();

    this.nick = nickname;
    this.image = scene.add.sprite(position.x, position.y, "player");
    this.zone = scene.add.zone(position.x, position.y, TILE_SIZE, TILE_SIZE);
    this.text = scene.add.text(position.x, position.y, this.nick, { fontSize: "12px" });
    this.textBackground = scene.add.rectangle(position.x, position.y, TILE_SIZE, this.text.height, 0x000000);

    this.textBackground.alpha = 0.5;
    this.textBackground.setOrigin(0, 0);
    this.textBackground.setDepth(0);

    this.text.setDepth(1);
    this.text.setColor("yellow");

    this.text.scaleY = 0;
    this.textBackground.scaleY = 0;
    this.image.scale = 0;
    scene.tweens.add({
      targets: [this.image, this.text, this.textBackground],
      scale: 1,
      ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: 750,
      repeat: 0, // -1: infinity
      yoyo: false,
    });
  }

  public update() {
    this.image.x = this.zone.x + this.image.width / 2;
    this.image.y = this.zone.y + this.image.height / 2;

    this.text.x = this.zone.x;
    this.text.y = this.zone.y;
    this.textBackground.x = this.text.x;
    this.textBackground.y = this.text.y;
  }

  public move(movement: { x: number; y: number }) {
    if (this.isMoving) {
      return;
    }

    const prevGridPosition = this.gridPosition.clone();

    this.gridPosition = new Phaser.Math.Vector2(this.gridPosition.x + movement.x, this.gridPosition.y + movement.y);
    this.gridPosition = this.clampPosition(this.gridPosition);

    const position = this.getRenderPosition();
    const tweens = [];
    if (movement.x !== 0) {
      tweens.push({
        x: position.x,
        ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
        duration: Math.abs(prevGridPosition.x - this.gridPosition.x) * PLAYER_TILE_DURATION,
        repeat: 0, // -1: infinity
        yoyo: false,
      });
    }

    if (movement.y !== 0) {
      tweens.push({
        y: position.y,
        ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
        duration: Math.abs(prevGridPosition.y - this.gridPosition.y) * PLAYER_TILE_DURATION,
        repeat: 0, // -1: infinity
        yoyo: false,
      });
    }

    if (tweens.length === 0) {
      return;
    }

    this.isMoving = true;
    const chain = this.zone.scene.tweens
      .chain({ targets: this.zone, tweens: tweens })
      .addListener("complete", () => this.onMovementComplete());

    chain.play();
  }

  private clampPosition(position: Phaser.Math.Vector2): Phaser.Math.Vector2 {
    const x = Phaser.Math.Clamp(position.x, 0, GRID_WIDTH - 1);
    const y = Phaser.Math.Clamp(position.y, 0, GRID_HEIGHT - 1);
    return new Phaser.Math.Vector2(x, y);
  }

  private getRenderPosition() {
    return new Phaser.Math.Vector2(this.gridPosition.x * TILE_SIZE, this.gridPosition.y * TILE_SIZE);
  }

  private onMovementComplete() {
    this.isMoving = false;

    const event: PlayerMovedEvent = { player: this, gridPosition: this.gridPosition };
    this.scene.events.emit("player-moved", event);
  }

  public destroy() {
    this.image.destroy();
    this.zone.destroy();
    this.text.destroy();
    this.textBackground.destroy();
  }
}
