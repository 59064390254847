import { GameBase } from "../game-base";
import { GRID_HEIGHT, GRID_WIDTH, TILE_SIZE } from "./constants";
import { TreasureHuntScene } from "./treasure-grid-scene";

export class TreasureHuntGame extends GameBase {
  constructor() {
    super();
    this.game = new Phaser.Game({
      width: TILE_SIZE * GRID_WIDTH,
      height: TILE_SIZE * GRID_HEIGHT,
      parent: "content",
      type: Phaser.AUTO,
      transparent: true,
      mode: Phaser.Scale.RESIZE,
      scene: TreasureHuntScene,
    });
  }

  override processPlayerInput(username: string, message: string) {
    const raceScene = this.game.scene.getAt(0) as TreasureHuntScene;
    raceScene.processPlayerInput(username, message);
  }

  public override getDefaultWidth() {
    return TILE_SIZE * GRID_WIDTH;
  }

  public override getDefaultHeight() {
    return TILE_SIZE * GRID_HEIGHT;
  }

  public override getMaxWidth() {
    return TILE_SIZE * GRID_WIDTH;
  }

  public override getMaxHeight() {
    return TILE_SIZE * GRID_HEIGHT;
  }
}
