import { GameBase } from "../game-base";
import { RaceScene } from "./race-scene";

export class JumperGame extends GameBase {
  constructor() {
    super();
    this.game = new Phaser.Game({
      width: 800,
      height: 600,
      parent: "content",
      type: Phaser.AUTO,
      transparent: true,
      mode: Phaser.Scale.FIT,
      scene: RaceScene,
      physics: {
        default: "arcade",
        arcade: {
          gravity: { y: 500 },
          debug: false,
        },
      },
    });
  }

  override processPlayerInput(username: string, message: string) {
    const raceScene = this.game.scene.getAt(0) as RaceScene;
    raceScene.processPlayerInput(username, message);
  }
}
