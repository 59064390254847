import { ApiClient } from "../api-client";
import { ChatListener } from "../chat-listener";
import { GRID_HEIGHT, GRID_WIDTH, SESSION_FETCH_INTERVAL, TILE_SIZE } from "./constants";
import { PlayerMovedEvent } from "./events/player-events";
import { Player } from "./player";
import { Reward } from "./reward";

export class TreasureHuntScene extends Phaser.Scene {
  private players: Player[] = [];
  private apiClient: ApiClient;
  private chatListener: ChatListener;

  private nextSessionFetch: number = 0;
  private rewards: Reward[] = [];

  constructor() {
    super();
    this.apiClient = ApiClient.instance;
    this.chatListener = ChatListener.instance;

    this.onChatListenerCreated();
  }

  preload() {
    this.load.setPath("../assets");
    this.load.image("player", "player.png");
    this.load.image("obstacle", "obstacle.png");
    this.load.image("treasure_tiles", "tile.png");
    this.load.spritesheet("rewards", "reward.png", { frameWidth: TILE_SIZE, frameHeight: TILE_SIZE });
  }

  create() {
    this.input.keyboard.on("keydown", (event: KeyboardEvent) => this.handleInput(event));

    const map = this.make.tilemap({
      width: GRID_WIDTH,
      height: GRID_HEIGHT,
      tileWidth: TILE_SIZE,
      tileHeight: TILE_SIZE,
    });
    const tiles = map.addTilesetImage("treasure_tiles", null, TILE_SIZE, TILE_SIZE);
    const layer = map.createBlankLayer("layer1", tiles);

    layer.depth = -1;
    layer.fill(0);

    this.events.on("player-moved", this.onPlayerMoved);
  }

  update(time: number, delta: number): void {
    this.players.forEach((player) => player.update());

    if (time > this.nextSessionFetch) {
      this.nextSessionFetch = time + SESSION_FETCH_INTERVAL;

      this.apiClient.fetchSessionState().then((state) => {
        for (const r of state.dropList) {
          let reward = this.rewards.find((re) => re.dropId === r.id);
          if (reward) {
            continue;
          }

          let position = r.position;
          if (!r.position) {
            position = { x: Phaser.Math.Between(0, GRID_WIDTH), y: Phaser.Math.Between(0, GRID_HEIGHT) };
          }

          reward = new Reward(this, r.id, r.reward, new Phaser.Math.Vector2(position.x, position.y));
          this.rewards.push(reward);
        }
      });
    }
  }

  addPlayer(nick: string) {
    if (this.players.indexOf(this.players.find((p) => p.nick === nick)) !== -1) {
      return;
    }

    const player = new Player(this, nick);
    this.players.push(player);
  }

  private handleInput(event: KeyboardEvent) {
    this.processPlayerInput("Player 1", "!joingame");
    if (event.key === "Enter") {
      const command = prompt("Enter your move", "!r2d3");
      if (command) {
        this.processPlayerInput("Player 1", command);
      }

      return;
    }

    if (event.key === "ArrowUp") {
      this.processPlayerInput("Player 1", "!up");
    } else if (event.key === "ArrowDown") {
      this.processPlayerInput("Player 1", "!down");
    } else if (event.key === "ArrowRight") {
      this.processPlayerInput("Player 1", "!right");
    } else if (event.key === "ArrowLeft") {
      this.processPlayerInput("Player 1", "!left");
    }
  }

  public processPlayerInput(nick: string, message: string) {
    const player = this.players.find((p) => p.nick === nick);
    if (!player) {
      if (message === "!joingame") {
        this.addPlayer(nick);
      }
      return;
    }

    const command = message.toLowerCase().trim();
    let movement = { x: 0, y: 0 };
    if (command === "!up") {
      movement = { x: 0, y: -1 };
    } else if (command === "!down") {
      movement = { x: 0, y: 1 };
    } else if (command === "!right") {
      movement = { x: 1, y: 0 };
    } else if (command === "!left") {
      movement = { x: -1, y: 0 };
    } else {
      movement = this.parseMovement(command);
      if (!movement) {
        return;
      }
    }

    player.move(movement);
  }

  private parseMovement(command: string) {
    if (!command || command.length < 2) {
      return null;
    }

    // Skip first letter
    command = command.substring(1);
    if (command.length == 1) {
      return this.parseDirection(command);
    }

    if (command.length == 2) {
      // Handle !r2, !l3, !u4, !d5 etc.
      if (Number.isInteger(parseInt(command[1]))) {
        const direction = this.parseDirection(command[0]);
        const distance = parseInt(command[1]);
        return { x: direction.x * distance, y: direction.y * distance };
      }

      const dir1 = this.parseDirection(command[0]);
      const dir2 = this.parseDirection(command[1]);

      return { x: dir1.x + dir2.x, y: dir1.y + dir2.y };
    }

    if (command.length != 4) {
      return null;
    }

    if (!Number.isInteger(parseInt(command[1])) || !Number.isInteger(parseInt(command[3]))) {
      return null;
    }

    // Handle !r2d3, !l3u4, !u4r2, !d5l3 etc.
    const dir1 = this.parseDirection(command[0]);
    const dir2 = this.parseDirection(command[2]);
    const distance1 = parseInt(command[1]);
    const distance2 = parseInt(command[3]);

    return { x: dir1.x * distance1 + dir2.x * distance2, y: dir1.y * distance1 + dir2.y * distance2 };
  }

  private parseDirection(command: string) {
    // p, r, e - right
    // l, w - left
    // g, u, n - up
    // d, s - down
    switch (command) {
      case "r":
      case "e":
      case "p":
        return { x: 1, y: 0 };
      case "l":
      case "w":
        return { x: -1, y: 0 };
      case "u":
      case "n":
      case "g":
        return { x: 0, y: -1 };
      case "d":
      case "s":
        return { x: 0, y: 1 };
    }

    return { x: 0, y: 0 };
  }

  private onPlayerMoved = (ev: PlayerMovedEvent) => {
    const tileReward = this.rewards.find((r) => r.gridPosition.equals(ev.gridPosition));

    if (!tileReward) {
      return;
    }

    tileReward.destroy();
    this.rewards = this.rewards.filter((r) => r !== tileReward);

    this.apiClient.sendDropClaimed(ev.player.nick, tileReward.dropId).then(() => {
      console.log("Drop claimed by ", ev.player.nick);
    });
  };

  private onPlayerPart(channel: string, username: string) {
    console.log("Player part", username);
    const player = this.players.find((p) => p.nick === username);
    if (player) {
      player.destroy();
      this.players = this.players.filter((p) => p !== player);
    }
  }

  private onPlayerJoinChannel(channel: string, username: string) {}

  public onChatListenerCreated() {
    if (this.chatListener) {
      this.chatListener.client.on("part", (channel, username) => this.onPlayerPart(channel, username));
      this.chatListener.client.on("join", (channel, username) => this.onPlayerJoinChannel(channel, username));
    }
  }
}
