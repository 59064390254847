import { ApiClient } from "./api-client";
import { ChatListener } from "./chat-listener";

const DEFAULT_WIDTH = 800;
const DEFAULT_HEIGHT = 600;
const MAX_WIDTH = 800;
const MAX_HEIGHT = 600;

export class GameBase {
  game: Phaser.Game;

  public processPlayerInput(username: string, message: string) {}

  public resize() {
    if (!this.game) {
      return;
    }

    const w = window.innerWidth;
    const h = window.innerHeight;

    let width = this.getDefaultWidth();
    let height = this.getDefaultHeight();
    let maxWidth = this.getMaxWidth();
    let maxHeight = this.getMaxHeight();

    let scale = Math.min(w / width, h / height);
    let newWidth = Math.min(w / scale, maxWidth);
    let newHeight = Math.min(h / scale, maxHeight);

    // resize the game
    this.game.scale.resize(newWidth, newHeight);

    // scale the width and height of the css
    this.game.canvas.style.width = newWidth * scale + "px";
    this.game.canvas.style.height = newHeight * scale + "px";

    // center the game with css margin
    this.game.canvas.style.marginTop = `${(h - newHeight * scale) / 2}px`;
    this.game.canvas.style.marginLeft = `${(w - newWidth * scale) / 2}px`;
  }

  public getDefaultWidth() {
    return DEFAULT_WIDTH;
  }

  public getDefaultHeight() {
    return DEFAULT_HEIGHT;
  }

  public getMaxWidth() {
    return MAX_WIDTH;
  }

  public getMaxHeight() {
    return MAX_HEIGHT;
  }
}
