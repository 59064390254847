import { TILE_SIZE } from "./constants";

export class Reward {
  dropId: number;
  sprite: Phaser.GameObjects.Sprite;
  gridPosition: Phaser.Math.Vector2 = new Phaser.Math.Vector2(0, 0);

  constructor(scene: Phaser.Scene, dropId: number, type: string, position: Phaser.Math.Vector2) {
    this.dropId = dropId;
    let spriteIndex = 0;
    switch (type) {
      case "treasure":
        spriteIndex = 0;
        break;
      default:
        spriteIndex = 1;
        break;
    }

    this.gridPosition = position;
    this.sprite = scene.add.sprite(position.x * TILE_SIZE, position.y * TILE_SIZE, "rewards", spriteIndex);
    this.sprite.setOrigin(0, 0);

    this.sprite.scale = 0;
    scene.tweens.add({
      targets: [this.sprite],
      scale: 1,
      ease: "Linear",
      duration: 500,
      repeat: 0,
      yoyo: false,
    });
  }

  public destroy() {
    this.sprite.destroy();
  }
}
