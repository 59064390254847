import { ApiClient, FakeApiClient } from "./api-client";
import { ChatListener } from "./chat-listener";
import { GameBase } from "./game-base";
import { JumperGame } from "./jumper/jumper-game";
import { TreasureHuntGame } from "./treasure-hunt/treasure-hunt-game";

const gameTypeOverride: string | null = "__gameTypeOverride__";
export class GameSession {
  private chatListener: ChatListener;
  private game: GameBase;

  constructor() {}

  async start() {
    let session: any = {};
    let apiClient: ApiClient;
    if (gameTypeOverride) {
      apiClient = new FakeApiClient("test", "test");
    } else {
      apiClient = this.createApiClient();
    }

    ApiClient.instance = apiClient;
    session = await apiClient.startGameSession();

    if (!gameTypeOverride) {
      this.chatListener = new ChatListener(session.channel, session.login, `oauth:${session.accessCode}`);
      ChatListener.instance = this.chatListener;
    }

    switch (session.type) {
      case "jumper":
        this.game = new JumperGame();
        break;
      case "treasure-grid":
        this.game = new TreasureHuntGame();
        break;
      default:
        this.game = new JumperGame();
        break;
    }

    if (this.chatListener) {
      this.chatListener.connect((message, username) => this.game.processPlayerInput(username, message));
    }
  }

  createApiClient() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const channel = urlParams.get("channel");
    const accessCode = urlParams.get("token");

    const apiClient = new ApiClient(channel, accessCode);
    return apiClient;
  }

  resize() {
    if (!this.game) {
      return;
    }
    this.game.resize();
  }
}
