import { SessionState } from "./treasure-hunt/session-state";

const apiUrl = "__apiURL";

export class ApiClient {
  sessionId: string;
  channel: string;
  accessCode: string;

  static instance: ApiClient;

  constructor(channel: string, accessCode: string) {
    this.channel = channel;
    this.accessCode = accessCode;
  }

  async startGameSession() {
    const response = await fetch(
      apiUrl + "/gamesession/start?channel=" + this.channel + "&accessCode=" + this.accessCode
    );
    const session = await response.json();
    this.sessionId = session.sessionId;
    return session;
  }

  async fetchSessionState(): Promise<SessionState> {
    const response = await fetch(
      apiUrl + `/gamesession/state?channel=${this.channel}&accessCode=${this.accessCode}&sessionId=${this.sessionId}`
    );
    const state = await response.json();
    return state;
  }

  async sendDropClaimed(playerNick: string, dropId: number) {
    const requesrt = {
      sessionId: this.sessionId,
      channel: this.channel,
      accessCode: this.accessCode,
      nickname: playerNick,
      dropId: dropId,
    };

    const response = await fetch(apiUrl + "/gamesession/claim-drop", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requesrt),
    });

    return {};
  }
}

export class FakeApiClient {
  channel: string;
  accessCode: string;
  sessionId: string;

  constructor(channel: string, accessCode: string) {
    this.channel = channel;
    this.accessCode = accessCode;
  }

  async startGameSession() {
    return {
      type: "__gameTypeOverride__",
      channel: this.channel,
      login: "test",
      accessCode: this.accessCode,
    };
  }

  static lastDropId = 0;
  async fetchSessionState(): Promise<SessionState> {
    console.log("fetchSessionState");

    const state = {
      dropList: [
        {
          id: FakeApiClient.lastDropId++,
          reward: "treasure",
          rewardCount: 1,
          position: {
            x: Phaser.Math.Between(0, 10),
            y: Phaser.Math.Between(0, 10),
          },
        },
      ],
    };

    return Promise.resolve(state);
  }

  async sendDropClaimed(playerNick: string, dropId: number) {
    console.log("sendRewardCollected", playerNick, dropId);
    return Promise.resolve({});
  }
}
