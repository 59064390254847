export class Obstacle extends Phaser.Physics.Arcade.Sprite {}

const OBSTACLE_TYPES = ["bouncy", "head", "legs"];
const MIN_VELOCITY = -100;

export class ObstaclesHandler extends Phaser.Physics.Arcade.Group {
  private lastObstacleTime = 0;
  constructor(scene: Phaser.Scene) {
    super(scene.physics.world, scene);
  }

  update(time: number, delta: number): void {
    for (let i = 0; i < this.getChildren().length; i++) {
      const obstacle = this.getChildren()[i] as Obstacle;

      obstacle.rotation -= 0.2;
      if (obstacle.x < 0) {
        obstacle.destroy();
        this.remove(obstacle);
      } else if (obstacle.body.velocity.x > MIN_VELOCITY) {
        obstacle.setVelocityX(MIN_VELOCITY);
      }
    }
  }

  public createObstacle() {
    const randomType = Math.floor(Math.random() * OBSTACLE_TYPES.length);
    if (OBSTACLE_TYPES[randomType] === "bouncy") {
      this.createBouncyObstacle();
    } else if (OBSTACLE_TYPES[randomType] === "head") {
      this.createLinearObstacle(100);
    } else if (OBSTACLE_TYPES[randomType] === "legs") {
      this.createLinearObstacle(50);
    }
  }

  private createBouncyObstacle() {
    const randomBounce = Math.random();
    const randomVelocity = Math.random() * 300;
    const obstacle = this.scene.physics.add.sprite(this.rightEdgeX, this.scene.game.canvas.height - 300, "obstacle");
    this.add(obstacle);

    obstacle.setBounce(randomBounce);
    obstacle.setVelocityX(-randomVelocity);
  }

  private createLinearObstacle(height: number) {
    const obstacle = this.scene.physics.add.sprite(this.rightEdgeX, this.bottomEdgeY - height, "obstacle");
    this.add(obstacle);

    obstacle.body.allowGravity = false;
    obstacle.body.immovable = true;
    obstacle.setVelocityX(-100);
  }

  private get rightEdgeX() {
    return this.scene.game.canvas.width;
  }

  private get bottomEdgeY() {
    return this.scene.game.canvas.height;
  }
}
