import tmi from "tmi";

export class ChatListener {
  static instance: ChatListener;

  client: any;

  constructor(channel: string, username: string, token: string) {
    console.log("ChatListener", channel, username, token);
    const isSecure = window.location.protocol === "https:";

    this.client = new tmi.Client({
      connection: {
        secure: isSecure,
        reconnect: true,
      },
      channels: [channel],
      identity: {
        username,
        password: token,
      },
    });
  }

  public connect(onMessage: (message: string, username: string) => void) {
    this.client.connect().catch(console.error);
    this.client.on("connected", (address, port) => {
      console.log(`Connected to ${address}:${port}`);

      // this.client.say(CHANNEL_NAME, "Hello world! I am a game bot.");
    });

    this.client.on("message", (channel, tags, message, self) => {
      if (self) return;

      const username = tags.username;
      onMessage(message, username);
      console.log(message, username);
    });
  }
}
