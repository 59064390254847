import { ObstaclesHandler } from "./obstacles";
import { Player } from "./player";

const OBSTACLE_SPAWN_TIME_MIN = 4000;
const OBSTACLE_SPAWN_TIME_MAX = 6000;

export class RaceScene extends Phaser.Scene {
  private players: Player[] = [];
  private floor: Phaser.GameObjects.Rectangle;
  private staticObjects: Phaser.Physics.Arcade.StaticGroup;
  private obstacles: ObstaclesHandler;
  private playersGroup: Phaser.Physics.Arcade.Group;
  private lastObstacleTime = 0;

  constructor() {
    super();
  }

  preload() {
    this.load.setPath("../assets");
    this.load.image("player", "player.png");
    this.load.image("obstacle", "obstacle.png");
  }

  create() {
    this.staticObjects = this.physics.add.staticGroup();
    this.playersGroup = this.physics.add.group();
    this.obstacles = new ObstaclesHandler(this);

    this.physics.add.collider(this.obstacles, this.staticObjects);
    this.physics.add.collider(this.playersGroup, this.staticObjects);

    this.input.keyboard.on("keydown", (event: KeyboardEvent) => this.handleInput(event));
    this.createFloor();
    this.addPlayer("Player 1");
  }

  update(time: number, delta: number): void {
    this.players.forEach((player) => player.update());
    this.obstacles.update(time, delta);

    if (time > this.lastObstacleTime) {
      this.lastObstacleTime =
        time + Math.random() * (OBSTACLE_SPAWN_TIME_MAX - OBSTACLE_SPAWN_TIME_MIN) + OBSTACLE_SPAWN_TIME_MIN;
      this.obstacles.createObstacle();
    }
  }

  addPlayer(nick: string) {
    if (this.players.indexOf(this.players.find((p) => p.nick === nick)) !== -1) {
      return;
    }

    const player = new Player(this, nick);
    this.players.push(player);
    this.playersGroup.add(player.zone);

    this.physics.add.overlap(player.obstacleDetector, this.obstacles, (p, o) =>
      this.onPlayerHitObstacle(player, o as any)
    );
  }

  private handleInput(event: KeyboardEvent) {
    if (event.key === "ArrowUp") {
      this.players[0].jump();
    } else if (event.key === "ArrowDown") {
      this.players[0].crouch();
    } else if (event.key === "ArrowRight") {
      this.players[0].moveRight();
    } else if (event.key === "ArrowLeft") {
      this.players[0].moveLeft();
    }
  }

  private createFloor() {
    const height = 30;
    const width = this.game.canvas.width;

    this.floor = this.add.rectangle(0, this.game.canvas.height - height, width, height, 0x000000);
    this.floor.setOrigin(0, 0);

    this.physics.add.existing(this.floor, true);
    this.staticObjects.add(this.floor);
  }

  private onPlayerHitObstacle(player: Player, obstacle: Phaser.Types.Physics.Arcade.GameObjectWithBody) {
    if (player.isDead()) {
      return;
    }

    if (obstacle.body.position.x < player.getBody().position.x) {
      return;
    }

    this.playersGroup.remove(player.zone);
    player.die(() => {
      this.players = this.players.filter((p) => p !== player);
    });
  }

  public processPlayerInput(nick: string, message: string) {
    const player = this.players.find((p) => p.nick === nick);
    if (!player) {
      if (message === "!joingame") {
        this.addPlayer(nick);
      }
      return;
    }

    const command = message.toLowerCase();
    if (command === "!up") {
      player.jump();
    } else if (command === "!down") {
      player.crouch();
    } else if (command === "!right") {
      player.moveRight();
    } else if (command === "!left") {
      player.moveLeft();
    }
  }
}
